import Link from 'next/link'
import styled from 'styled-components'

import OrangeArrow from '../../src/components/icons/OrangeArrow'
import RichText from '../../src/components/RichText'
import { media } from '../../src/utils/style-utils'
import NewsCards from '../../src/components/NewsCards/NewsCards'
import { NewsSliceSlice, NewsarticleDocument } from '../../prismicio-types'

interface INewsSliceWrapper {
	slice: NewsSliceSlice
	newsArticlesData: NewsarticleDocument[]
}

const NewsSliceWrapper: React.FC<INewsSliceWrapper> = ({ slice, newsArticlesData }) => {
	if (newsArticlesData.length > 0) {
		return (
			<StyledNewsSliceWrapper>
				<StyledNewsSliceHeader>
					<StyledRichText content={slice.primary.title} />

					{/* REFACTOR TODO: Test this href functionality */}
					{/* @ts-ignore */}
					<StyledDeskopLink href={slice.primary.link}>
						<StyledLinkIcon>
							{slice.primary.link_text}
							<OrangeArrow />
						</StyledLinkIcon>
					</StyledDeskopLink>
				</StyledNewsSliceHeader>
				<StyledNewsCards
					newsArticlesData={newsArticlesData}
					onFrontpage={true}
				/>
				{/* REFACTOR TODO: Test this href functionality */}
				{/* @ts-ignore */}
				<StyledLink href={slice.primary.link}>
					<StyledLinkIcon>
						{slice.primary.link_text}
						<OrangeArrow />
					</StyledLinkIcon>
				</StyledLink>
			</StyledNewsSliceWrapper>
		)
	} else {
		return <></>
	}
}

export default NewsSliceWrapper

const StyledNewsSliceWrapper = styled.section`
	background-color: var(--blue-100);
	padding: 64px 72px 48px;
	height: 600px;
	border-radius: 56px;
	margin: 24px 40px 0;
	height: fit-content;

	${media.desktopMd`
		margin: 24px 24px 0;
		padding: 64px 48px 48px;

	`}

	${media.tabletMd`
		display: flex;
		flex-direction: column;
		padding: 64px 48px 48px;
		margin: 24px 20px 0;
	`}

	${media.phone`
		padding: 48px 24px 38px;
		margin: 24px 8px 0;

	`}
`

const StyledNewsSliceHeader = styled.div`
	display: flex;
	align-items: center;
	height: 64px;

	${media.tabletMd`
		display: flex;
		flex-direction: column;
		height: fit-content;
		margin: 0;
		align-items: flex-start;

	`}
`

const StyledRichText = styled(RichText)`
	${media.tabletMd`
 		width: 80%;
 	`}

	h1 {
		margin: 0;
		font-size: 56px;
		line-height: 64px;
	}
`

const StyledDeskopLink = styled(Link)`
	margin-left: auto;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 24px;
	color: var(--deep-blue);

	${media.tabletMd`
		display: none;
	`}
`

const StyledLink = styled(Link)`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 24px;
	color: var(--deep-blue);
	display: none;

	${media.tabletMd`
		display: flex;
		flex-direction: column;
		height: fit-content;
		margin: 0;
		align-items: center;

	`}
`

const StyledLinkIcon = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;

	svg {
		margin-left: 17.5px;
	}

	${media.tabletSm`
		padding: 0 16px;
	`}
	${media.tabletMd`
 		width: 80%;
 	`}
`

const StyledNewsCards = styled(NewsCards)`
	margin: 48px 0;

	${media.phone`
		margin: 24px 0;
	`}
`
