import dayjs from 'dayjs'
import { sample } from 'lodash'
import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import { media } from '../../utils/style-utils'

const COLORS = ['var(--neon-100)', 'var(--baby-blue-200)', 'var(--orange-100)']

function NewsCard({ image, title, date, href, uid, className }) {
	const randomColor = sample(COLORS)
	return (
		<CardWrapper
			href={`frettir/${uid}`}
			className={className}
		>
			<ImageWrapper backgroundColor={randomColor ?? COLORS[0]}>
				{image && (
					<CardImage
						alt='' // * decorative image
						fill
						className='image'
						src={image.url}
					/>
				)}
			</ImageWrapper>
			<ArticleDetailsWrapper>
				<Headline className='underline'>{title}</Headline>
				<Date>{dayjs(date).isValid() ? dayjs(date).format('DD.MM.YYYY') : date}</Date>
			</ArticleDetailsWrapper>
		</CardWrapper>
	)
}

export default NewsCard

const CardWrapper = styled.a`
	all: unset;
	height: 450px;
	width: 100%;
	border-radius: 40px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	transition: var(--animation-transition);

	&:hover {
		box-shadow: 0px 4px 8px -3px rgba(37, 80, 120, 0.2);
		filter: drop-shadow(0px 12px 17px rgba(0, 0, 0, 0.05));

		.underline {
			text-decoration: underline;
		}

		.image {
			transform: scale(1.1);
		}
	}

	&:focus-visible {
		outline: 3px solid var(--orange);
		outline-offset: 1px;
	}
	${media.desktopMd`
		height: 376px;
	`}

	${media.desktopLg`
		height: 576px;
	`}

	${media.desktopXl`
		height: 676px;
	`}

	${media.desktopHuge`
		height: 976px;
	`}
`

const Headline = styled.h5`
	font-size: 1.5rem;
	line-height: 1.5rem;
	font-weight: 500;
	margin: 0;

	overflow: hidden;
	text-overflow: ellipsis;

	${media.phone`
		font-size: 20px;
	`}
`

const Date = styled.span`
	font-weight: 400;
	margin: 0;
	color: var(--blue-400);
	position: relative;
	top: 8px;
`

const ImageWrapper = styled.div<{ backgroundColor: string }>`
	background: ${(p) => p.backgroundColor};
	width: 100%;
	flex-basis: 60%;
	position: relative;
	overflow: hidden;
`

const CardImage = styled(Image)`
	object-fit: cover;
	height: 100%;
	width: 100%;

	transition: var(--animation-transition);
`

const ArticleDetailsWrapper = styled.div`
	width: 100%;
	flex-basis: 40%;
	padding-left: 48px;
	padding-right: 24px;
	padding-block: 28px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	h5 {
		overflow: unset;
	}

	${media.tablet`
		padding-left: 24px;
	`}
`
