import { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../utils/style-utils'
import NewsCard from '../NewsCard/NewsCard'
import { NewsarticleDocument, NewsarticleDocumentData } from '../../../prismicio-types'

interface INewsCards {
	newsArticlesData: NewsarticleDocument[]
	onFrontpage: boolean
	className?: string
}

const NewsCards: FC<INewsCards> = ({ onFrontpage = false, newsArticlesData, className }) => {
	const sortByDueDate = () => {
		return newsArticlesData.sort((a: NewsarticleDocument, b: NewsarticleDocument) => {
			if (!a.data.published_date || !b.data.published_date) return 0
			// Unary plus (+) converts an operand ( new Date() ) into a number.
			return new Date(b.data.published_date).getTime() > new Date(a.data.published_date).getTime() ? 1 : -1
		})
	}

	const numberOfItems = onFrontpage ? 3 : newsArticlesData.length
	const sortedNewsArticles = sortByDueDate().slice(0, numberOfItems)

	return (
		<StyledNewsCards className={className}>
			{sortedNewsArticles.map((item, index) => (
				<StyledNewsCard
					image={item.data.preview_image}
					date={item.data.published_date}
					title={item.data.title}
					href={item.href}
					key={index}
					uid={item.uid}
					className
				/>
			))}
		</StyledNewsCards>
	)
}

export default NewsCards

const StyledNewsCards = styled.div`
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(3, 1fr);
	gap: 48px 24px;

	${media.tabletMd`
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
	`}
`

const StyledNewsCard = styled(NewsCard)`
	${media.tabletSm`
		height: 351px;
	`};
`
