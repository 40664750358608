import React from 'react'

import NewsSliceWrapper from './NewsSliceWrapper'

/**
 * @typedef {import("@prismicio/client").Content.NewsSliceSlice} NewsSliceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<NewsSliceSlice>} NewsSliceProps
 * @param { NewsSliceProps }
 */
const NewsSlice = (props) => (
	<NewsSliceWrapper
		slice={props.slice}
		newsArticlesData={props.context.newsArticlesData}
	/>
)

export default NewsSlice
