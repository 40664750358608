import { KvikaSVGProps } from './types'

const OrangeArrow = ({ width = 22, height = 20 }: KvikaSVGProps): JSX.Element => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 22 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M21.0593 11.059C21.6453 10.473 21.6453 9.52148 21.0593 8.93555L13.5593 1.43555C12.9734 0.849609 12.0218 0.849609 11.4359 1.43555C10.85 2.02148 10.85 2.97305 11.4359 3.55898L16.3812 8.49961L1.99995 8.49961C1.17026 8.49961 0.49995 9.16992 0.49995 9.99961C0.49995 10.8293 1.17026 11.4996 1.99995 11.4996L16.3812 11.4996L11.4406 16.4402C10.8546 17.0262 10.8546 17.9777 11.4406 18.5637C12.0265 19.1496 12.9781 19.1496 13.564 18.5637L21.064 11.0637L21.0593 11.059Z'
				fill='#FF8B59'
			/>
		</svg>
	)
}

export default OrangeArrow
